export const videoUrlRegex =
  /cloudflarestream\.com|\.mp4$|(?:https?:\/\/)?(?:www\.|go\.)?(?:twitch\.tv\/videos\/(\d{10,})|twitch\.tv\/([a-zA-Z0-9_]+)\/v\/(\d{10,})|youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})|youtu\.be\/([a-zA-Z0-9_-]{11})|youtube\.com\/shorts\/([a-zA-Z0-9_-]+)|video\.twimg\.com\/.*\.mp4)/;

export const showVidControlsRegex =
  /cloudflarestream\.com|\.mp4$|video\.twimg\.com\/.*\.mp4/;

export const xVideoUrlRegex =
  /(?:https?:\/\/)?(?:www\.)?(?:x|twitter)\.com\/[a-zA-Z0-9_]+\/status\/(\d+)/;

export const imageUrlRegex = /imagedelivery\.net|\.jpeg$|\.png$/;
export function sanitizeContent(content: string) {
  const mediaUrls = content.match(new RegExp(videoUrlRegex, "g"));
  let sanitizedContent = content.substring(0, 500);

  if (mediaUrls) {
    mediaUrls.forEach((url) => {
      sanitizedContent = sanitizedContent.replace(url, "");
    });
  }
  return sanitizedContent;
}
